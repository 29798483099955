<template>
  <div>
    <header-content />
    <main>
      <div class="content">
        <router-view />
      </div>
    </main>
    <aside class="left" />
    <aside class="right" />
    <footer class="contrast">
      <p>Copyright &copy; Chitwood Landscape. All Rights Reserved</p>
    </footer>
  </div>
</template>

<script>
import HeaderContent from '@/components/HeaderContent';

export default {
  name: 'App',
  components: {
    HeaderContent,
  },
};
</script>

<style lang="scss">
@import "~@/styles/base.scss";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.content {
  overflow: hidden;
}
</style>
