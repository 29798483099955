import { createRouter, createWebHistory } from 'vue-router';
import { updateMeta } from '@/lib/RouteFunctions';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html
    // and docs for router next say to return an object for scroll position
    // but the example is clearly not working in vue 3 so the following is a hack

    let position;
    if (to.hash) {
      const el = document.querySelector(to.hash);
      const { top, left } = el.getBoundingClientRect();
      position = { top, left, behavior: 'smooth' };
    } else if (savedPosition) {
      position = { top: savedPosition.y, left: savedPosition.x };
    } else {
      position = { top: 0, left: 0 };
    }

    window.scrollTo(position);
  },
});

// This callback runs before every route change, including on page load.
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  updateMeta(to, from, next);
  next();
});

export default router;
