const firstYear = 1979;
const currentYear = new Date().getFullYear();
const yearsExperience = currentYear - firstYear;

// NOTE: sitemap plugin makes very strict requirements for routes file
// we do not have access to ES6 here or in anything else required
// by the sitemap plugin - including imports or immediately
// requiring .vue files

// eslint-disable-next-line import/prefer-default-export
module.exports = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Professional Landscape Design | Roanoke VA | Dan Chitwood',
      metaTags: [
        {
          name: 'description',
          content: `Get your next landscaping project started with a design from an artisan and a certified professional. Serving Roanoke Virginia for ${yearsExperience} years. Get a quote today!`,
        },
      ],
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      title: 'Profile | Dan Chitwood Landscaping',
      metaTags: [
        {
          name: 'description',
          content: `Dan Chitwood has over ${yearsExperience} years of experience in landscape archi­tect­ure. Starting his career in project management for architecture, planning, engineering, and surveying firms and later starting his own firm, Dan Chitwood is a certified master of his craft and will bring art and engineering together for your next project.`,
        },
      ],
    },
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
  },
  {
    path: '/consultation-and-design',
    name: 'Consultation and Design',
    meta: {
      title: 'Consultation and Design | Dan Chitwood Landscaping',
      metaTags: [
        {
          name: 'description',
          content: 'Dreams become reality when you start with a master plan. An initial consultation is an important first step to your dream landscape coming together. Learn everything you need to know before getting started on your next project.',
        },
      ],
    },
    component: () => import(/* webpackChunkName: "consultation-and-design" */ '../views/Consultation.vue'),
  },
  {
    path: '/visit-my-yard',
    name: 'Visit My Yard',
    meta: {
      title: 'Visit My Yard | Dan Chitwood Landscaping',
      metaTags: [
        {
          name: 'description',
          content: 'Visit the yard of a landscape design master. Over the years Dan Chitwood has designed and developed several master plans for his properties. Take a look at the different techniques and designs created.',
        },
      ],
    },
    component: () => import(/* webpackChunkName: "yard" */ '../views/VisitYard.vue'),
  },
  {
    path: '/plant-schedule',
    name: 'Plant Schedule',
    meta: {
      title: 'Plant Schedule | Dan Chitwood Landscaping',
      metaTags: [
        {
          name: 'description',
          content: 'Dan Chitwood\'s personal encyclopedia of plants and landscaping terms explained and outlined for anyone to easily use and understand. Any plants or details needed in your master plan can be found here.',
        },
      ],
    },
    component: () => import(/* webpackChunkName: "plant-schedule" */ '../views/PlantSchedule.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: 'Contact | Dan Chitwood Landscaping',
      metaTags: [
        {
          name: 'description',
          content: 'Connect with Dan Chitwood - registered land­scape archi­tect­, centered in Roanoke, VA. Begin your consultation for your landscape master plan today.',
        },
      ],
    },
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/design-fees',
    name: 'Design Fees',
    meta: {
      title: 'Design Fees | Dan Chitwood Landscaping',
      metaTags: [
        {
          name: 'description',
          content: 'Conceptual master plans are a great start for projects of any size. See what a consultation covers, including Plantings, Hardscaping, Grading, Drainage, Lighting, Irrigation and Cost Considerations.',
        },
      ],
    },
    component: () => import(/* webpackChunkName: "fees" */ '../views/DesignFees.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    meta: {
      title: 'Page Not Found | Dan Chitwood Landscaping',
      metaTags: [
        {
          name: 'description',
          content: 'This page could not be found.',
        },
      ],
    },
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
  },
];
