<template>
  <header
    id="header"
    :class="{ contrast: !scrolledToTop || isMobile }"
    v-scroll="onScroll"
  >
    <nav class="header-container">
      <div>
        <router-link to="/" class="logo-container" @mouseleave="resetHoverBar">
          <span class="icon-logo gradient" />
          <span class="heading">Dan Chitwood</span>
        </router-link>
      </div>
      <button
        class="hamburger hamburger--stand"
        :class="{ 'is-active': isOpen }"
        aria-label="Menu"
        role="button"
        aria-controls="navigation"
        @click="isOpen = !isOpen"
      >
        <div class="hamburger-box">
          <div class="hamburger-inner"></div>
        </div>
      </button>
      <ul id="menu" :class="{ mobile: isOpen }">
        <li class="mobile-heading">
          <router-link to="/">Dan Chitwood Landscape Architecture</router-link>
        </li>
        <li @mouseenter="moveHoverBar" @mouseleave="resetHoverBar">
          <a href="###" aria-haspopup="true">Services &nbsp; <span class="chevron" /></a>
          <ul
            class="dropdown rounded-bottom-right"
            aria-label="submenu"
          >
            <li
              v-for="link in services"
              :key="link.route"
            >
              <router-link :to="link.route">{{ link.text }}</router-link>
            </li>
          </ul>
        </li>
        <li
          v-for="link in links"
          :key="link.route"
          @mouseenter="moveHoverBar"
          @mouseleave="resetHoverBar"
        >
          <router-link :to="link.route">{{ link.text }}</router-link>
        </li>
        <li @mouseleave="resetHoverBar">
          <router-link to="/contact">
            <button>Contact Me</button>
          </router-link>
        </li>
        <div class="hoverbar" ref="hoverBar"></div>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  data: () => ({
    scrolledToTop: true,
    isOpen: false,
    isMobile: false,
    services: [
      {
        route: '/consultation-and-design',
        text: 'Consultation and Design',
      },
      {
        route: '/design-fees',
        text: 'Design Fees',
      },
    ],
    links: [
      {
        route: '/profile',
        text: 'Profile',
      },
      {
        route: '/plant-schedule',
        text: 'Plant Schedule',
      },
      {
        route: '/visit-my-yard',
        text: 'Visit My Yard',
      },
    ],
  }),
  watch: {
    $route() {
      // close menu after link clicked
      this.isOpen = false;
      // clear focus to close dropdown menus
      document.activeElement.blur();
    },
  },
  methods: {
    randomColor() {
      const colors = [
        'var(--alt)',
        'var(--accent)',
        'var(--accent-dark)',
        '#42b983',
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    moveHoverBar(e) {
      // move bar above link
      this.$refs.hoverBar.style.left = `${e.target.offsetLeft}px`;
      this.$refs.hoverBar.style.width = `${e.target.clientWidth}px`;
      this.$refs.hoverBar.style.background = this.randomColor();
    },
    resetHoverBar() {
      let activeLink = document.querySelector('li a.router-link-active');
      let linkIsButton = false;
      if (activeLink) {
        // if children contain a button tag - skip this element
        const children = [...activeLink.children];
        linkIsButton = children.reduce((a, c) => a || c.tagName.toLowerCase() === 'button', false);
      }

      if (activeLink && !linkIsButton) {
        // for dropdowns we want to highlight the root li
        if (activeLink.closest('ul').classList.contains('dropdown')) {
          activeLink = activeLink.closest('ul').parentElement;
        }
        // set bar back to active link
        this.$refs.hoverBar.style.left = `${activeLink.offsetLeft}px`;
        this.$refs.hoverBar.style.width = `${activeLink.offsetWidth}px`;
      } else {
        // hide bar again
        this.$refs.hoverBar.style.width = 0;
      }
    },
    onScroll() {
      if (window.scrollY > 50) {
        this.scrolledToTop = false;
      } else {
        this.scrolledToTop = true;
      }
    },
    checkIfMobile() {
      // scss variable width for header-break
      if (window.innerWidth <= 1200) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.isOpen = false;
        this.onScroll();
      }
    },
  },
  created() {
    window.addEventListener('resize', this.checkIfMobile);
  },
  mounted() {
    this.$nextTick(async () => {
      // router link classes take a bit of extra time to apply after component renders
      await new Promise((r) => setTimeout(r, 500));
      this.resetHoverBar();
    });
    this.checkIfMobile();
  },
  onUnmounted() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
};
</script>

<style lang="scss" scoped>
$hamburger-layer-color: var(--base) !default;

@import "~include-media/dist/_include-media.scss";
@import "~hamburgers/_sass/hamburgers/hamburgers.scss";

.hamburger {
  display: none;
}

.mobile {
  display: flex !important;
  flex-direction: column;
  flex-basis: 100%;

  li {
    width: 100%;
    padding: 16px;

    ul {
      position: relative;
      border: var(--accent) 4px solid;
    }
  }
}

.mobile-heading {
  display: none;
}

@include media("<=phone") {
  .heading {
    display: none;
  }

  .mobile-heading {
    display: inline-block;
  }
}

@include media("<=header-break") {
  .hamburger {
    display: inline-block;
  }

  #menu {
    display: none;
  }
}

header {
  position: fixed;
  width: 100%;
  transition: all 0.15s ease;
  z-index: 1;

  &.contrast {
    .icon-logo {
      font-size: 4rem;
    }

    .dropdown {
      background-color: var(--contrast);
    }
  }

  .heading {
    margin: 0;
  }

  .chevron {
    &:before {
      content: '\203A';
      display: inline-block;
      transform: rotate(90deg);
      padding-left: 0.3rem;
    }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 24px 4px 24px;
    font-size: large;
    font-family: "Ubuntu", sans-serif;

    .logo-container {
      display: flex;
      align-items: center;
      text-decoration: none !important;
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 8px 0 8px 0;

    li {
      float: left;
      transition: 0.5s ease;
      -webkit-transition: 0.5s ease;
      cursor: pointer;

      a {
        padding: 0.5rem;
        text-decoration: none !important;
      }

      &:hover, &:focus-within {
        & > ul {
          visibility: visible;
          opacity: 1;
          display: block;
        }
      }

      .dropdown {
        border-top: var(--accent) 4px solid;
        overflow: hidden;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: var(--shadow);
        padding-top: 4px;
      }

      // second layer drop down menus
      ul {
        visibility: hidden;
        opacity: 0;
        min-width: 5rem;
        position: absolute;
        transition: all 0.5s ease;
        padding: 0;
        display: none;

        &:hover, &focus {
          visibility: visible;
          opacity: 1;
          display: block;
        }

        li {
          clear: both;
          width: 100%;
          text-align: left;
          padding: 0.5rem;
          padding-left: 0;
        }
      }

      button {
        margin-bottom: 0;
      }
    }
  }

  .hoverbar {
    width: 0;
    top: 0;
    height: 5px;
    background: var(--accent-dark);
    position: absolute;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
  }
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
