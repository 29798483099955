<template>
  <div ref="wrapper" :class="{ fade: !scrolledIntoView }">
    <slot />
  </div>
</template>

<script>
// TODO: in other non-vue projects we achieved the same functionality with only
// one observer object instead of making a new observer for each target...
// TODO: library
// TODO: animation flexibility
export default {
  data: () => ({
    scrolledIntoView: false,
  }),
  mounted() {
    const intersectionObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        // when element is in viewport, animate
        if (entry.intersectionRatio > 0) {
          this.scrolledIntoView = true;

          observer.unobserve(entry.target);
        }
      });
    });

    // start observing
    intersectionObserver.observe(this.$refs.wrapper);
  },
};
</script>

<style lang="scss" scoped>
div {
  opacity: 1;
  transition: opacity 0.8s ease 0.3s;

  .fade {
    opacity: 0 !important;
  }
}
</style>
