import { createApp } from 'vue';
import ArticleWrapper from '@/components/ArticleWrapper';
import IntersectionAnimation from '@/components/IntersectionAnimation';
import App from './App';
import router from './router';

const app = createApp(App);

app.component('ArticleWrapper', ArticleWrapper);
app.component('IntersectionAnimation', IntersectionAnimation);

app.directive('scroll', {
  mounted(el, binding) {
    const f = (event) => {
      if (binding.value(event, el)) {
        window.removeEventListener('scroll', f);
      }
    };

    window.addEventListener('scroll', f);
  },
});

app.use(router).mount('#app');
