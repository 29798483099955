<template>
  <article>
    <h1 id="heading">{{ heading }}</h1>
    <slot />
  </article>
</template>

<script>
export default {
  props: ['heading'],
};
</script>

<style lang="scss">
@import "~include-media/dist/_include-media.scss";
article {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  margin: 0 auto;
  padding: 100px 1rem 0 1rem;
  line-height: 1.9;
  font-size: larger;

  * {
    transition: all 0.15s;
  }

  p, ul {
    text-align: justify;
  }

  h1 {
    font-size: 3em;
    font-weight: 900;
  }

  h2 {
    font-size: 2.75em;
  }

  @include media("<=tablet") {
    img {
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
      width: 100vw;
      max-width: none;
    }
  }
}
</style>
